import styled from "@emotion/styled";

import { keyframes } from "@emotion/react";

import Main from "pages/home/c01.main/Main";
import Intro from "pages/home/c02.intro/Intro";
import Octo from "pages/home/c03.octo/Octo";
import Handy from "pages/home/c04.handy/Handy";
import Links from "pages/home/c05.links/Links";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { scrollListState, scrollState } from "app/store";
import Footer from "components/Footer";
import { useEffect, useRef } from "react";

const LayoutContainer = () => {
  const footerRef = useRef<HTMLDivElement | null>(null);

  const scroll = useRecoilValue(scrollState);
  const scrollList = useRecoilValue(scrollListState);
  const setScrollList = useSetRecoilState(scrollListState);

  useEffect(() => {
    checkScrollList();
    window.addEventListener("resize", checkScrollList);
    return () => window.removeEventListener("resize", checkScrollList);
  }, []);

  function checkScrollList() {
    const height = window.innerHeight;
    const footerHeight = footerRef.current?.offsetHeight;

    if (footerHeight) {
      setScrollList([
        0,
        (height + 1) * -1,
        (height * 2 + 2) * -1,
        (height * 3 + 3) * -1,
        (height * 4 + 4) * -1,
        (height * 4 + footerHeight) * -1,
      ]);
    }
  }

  return (
    <Container prevIdx={scroll.prevIdx} currIdx={scroll.currIdx} scrollList={scrollList}>
      <Main />
      <Intro />
      <Octo />
      <Handy />
      <Links />
      <div ref={footerRef}>
        <Footer />
      </div>
    </Container>
  );
};

const move = (prevIdx: number, currIdx: number, scrollList: number[]) => keyframes`
  0%{
    top:${scrollList[prevIdx]}px;
  }

  100%{
    top:${scrollList[currIdx]}px;
  }
`;

const Container = styled.div<{ prevIdx: number; currIdx: number; scrollList: number[] }>`
  position: absolute;
  left: 0;

  animation: ${({ prevIdx, currIdx, scrollList }) => move(prevIdx, currIdx, scrollList)} 0.5s 0s forwards;

  @media (max-width: 768px) {
    position: inherit;
    animation: none;
  }
`;

export default LayoutContainer;
