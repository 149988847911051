import styled from "@emotion/styled";

const PolicyE = () => {
  return (
    <Container>
      <Title>PRIVACY POLICY</Title>
      <Pre>
        {`[COMPANY] (hereinafter referred to as the `}
        <B>“Company”</B>
        {`) respects privacy of individual users (`}
        <B>“User”</B>
        {`) and is committed to protecting it through the Company’s compliance with this Privacy Policy (hereinafter referred to as this `}
        <B>“Policy”</B>
        {`). This Policy is applicable to personal information collected in [SERVICE NAME] services (`}
        <B>“Service”</B>
        {`) operated by the Company. \n \n The Company is the controller of the personal information provided by a User or collected from a User. This Policy describes the types of personal information the Company may collect from a User or that User may provide, and the Company’s practices and purpose for collecting, using, maintaining, protecting, and disclosing the personal information. The Company endeavors to process user’s personal information in compliance with the European Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data and repealing Directive 95/46/EC (General Data Protection Regulation or `}
        <B>“GDPR”</B>
        {`), and with the Korean Personal Information Protection Act (`}
        <B>“PIPA”</B>
        {`) and any other applicable data protection law.\n \n This Policy shall take effect on [2020.12.24] and, in case of modification thereof, the Company will make public notice of it by posting them on the Company’s Service or by using other methods (via text, e-mail, messenger or pop-up screen requesting acceptance at the time of a sign-in). \n \n Terms used in this Privacy Policy shall have the meaning set forth in the relevant laws and regulations and the Company's terms and conditions; other matters shall be subject to general commercial practice.\n \n`}
        <B>1. PERSONAL INFORMATION TO BE COLLECTED </B>
        {`\n \n (1) Personal information provided by Users or third-party service provider\n \n The Company may receive the following personal information from Users upon registration, or from third party service providers upon User’s consent:\n \n`}
        <table style={{ maxWidth: "950px" }}>
          <tr>
            <TdTitle>Required Information</TdTitle>
            <Td>
              Upon registration: Email address, password, User’s ID, date of birth, mobile phone number, profile picture
            </Td>
          </tr>
          <tr>
            <TdTitle>Optional Information</TdTitle>
            <Td>Name, sex, resident registration number, electronic wallet address</Td>
          </tr>
        </table>
        {`\n (2) Personal information collected while User’s use of Service \n \n Besides the personal information directly provided by the Users, the Company may also collect the following personal information that are automatically created during a User’s use of the Service: \n \n  IP address, cookie, MAC address, equipment information, operation system, hardware information, language settings of equipment, country setting, access records, log data, use time, use and faults records\n \n (3) Personal Information provided by Users during customer service\n \n  Name, ID, mobile phone number \n \n (4) Sensitive Personal Information\n \n The Company does not collect any sensitive personal information about Users (this includes details about a User’s race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about health, and genetic and biometric data). Nor does the Company collect any information about criminal convictions and offences.\n \n`}
        <B>2. USE OF COLLECTED PERSONAL INFORMATION </B>
        {`\n \n The Company uses the collected personal information of Users for the following purposes: \n \n (1) User registration, identification and management; \n (2) To provide the Company’s Services; \n (3) To provide marketing and advertising information in accordance with applicable laws; \n (4) To analyze the effects of Services; \n (5) To calculate and claim fees for the Services; \n (6) To respond to User’s requests, make announcements, and provide customer services; \n (7) To pursue legitimate interest of the Company, except where such interests are overridden by the interests or fundamental rights and freedoms of the Users; and\n (8) To use personal information with prior consent of the Users.\n \n The Company agrees that it will obtain consent from each User, if the Company desires to use the personal information for any purposes other than those expressly stated in this Policy.\n \n`}
        <B>3. RETENTION PERIOD OF PERSONAL INFORMATION </B>
        {`\n \n In principle, the Company destructs personal information of Users without delay when: the purpose of its collection and use has been achieved; a User withdraws from the Website; the legal or management needs are satisfied; or receipt of Users’ request. However, the Company will retain the following information for the duration as set forth below, as required by relevant laws and regulations:\n \n (1) Log record of Users such as internet/data detecting the place of User connection: 3 months (The Protection of Communications Secrets Act)\n (2) Record regarding contract or withdrawal of subscription in case of electronic commerce: 5 years (The Act on Consumer Protection in Electronic Commerce) \n (3) Record on payment and supply of goods in case of electronic commerce: 5 years (The Act on Consumer Protection in Electronic Commerce) \n (4) Record on consumer complaint or dispute treatment in case of electronic commerce: 3 years (The Act on Consumer Protection in Electronic Commerce) \n (5) Record on electronic financial transaction: 5 years (Electronic Financial Transactions Act) \n \n`}
        <B>4. DISCLOSURE AND CONSIGNMENT OF PERSONAL INFORMATION </B>
        {`\n \n (1) Disclosure of personal information to a third party \n \n The Company will process a User’s personal information only for the purpose set forth in Article 3 above and shall disclose personal information to a third party only if the User consents to such disclosure or such disclosure is required by laws. \n \n (2) Consignment of personal information\n \n To provide the Services, the Company may consign an external professional company or companies (subcontractors) to process personal information as described in this Article 4. This consigned processing of personal information is carried out by each subcontractor, only if necessary to obtain such processing services. \n In consigning process of personal information, in order to protect the personal information, the Company will expressly state in its agreement with the subcontractors that those subcontractors will safely process and protect personal information in accordance with the applicable laws and regulations. \n In case any of the below subcontractors are changed, the Company will immediately announce the change by amending this Policy. \n \n (a) To send text messages: \n`}
        <table>
          <tr>
            <TdTitle>Name of subcontractor</TdTitle>
            <TdTitle></TdTitle>
          </tr>
          <tr>
            <TdSubTitle>Contact Information</TdSubTitle>
            <Td></Td>
          </tr>
          <tr>
            <TdSubTitle>Purpose and Description of consigned works</TdSubTitle>
            <Td>To send text messages for identification purposes when a User registers at the Services</Td>
          </tr>
          <tr>
            <TdSubTitle>Provided items</TdSubTitle>
            <Td></Td>
          </tr>
          <tr>
            <TdSubTitle>Date of data provision</TdSubTitle>
            <Td>When personal information is collected</Td>
          </tr>
          <tr>
            <TdSubTitle>Method of provision</TdSubTitle>
            <Td></Td>
          </tr>
          <tr>
            <TdSubTitle>Term of Data Usage</TdSubTitle>
            <Td>Until expiration of Service</Td>
          </tr>
        </table>
        {`\n (b) To provide payment gateway services:`}
        <table style={{ maxWidth: "950px" }}>
          <tr>
            <TdTitle>Name of subcontractor</TdTitle>
            <TdTitle></TdTitle>
          </tr>
          <tr>
            <TdSubTitle>Contact Information</TdSubTitle>
            <Td></Td>
          </tr>
          <tr>
            <TdSubTitle>Purpose and Description of consigned works</TdSubTitle>
            <Td>To provide payment gateway services</Td>
          </tr>
          <tr>
            <TdSubTitle>Provided items</TdSubTitle>
            <Td></Td>
          </tr>
          <tr>
            <TdSubTitle>Date of data provision</TdSubTitle>
            <Td>When personal information is collected</Td>
          </tr>
          <tr>
            <TdSubTitle>Method of provision</TdSubTitle>
            <Td></Td>
          </tr>
          <tr>
            <TdSubTitle>Term of Data Usage</TdSubTitle>
            <Td>Until expiration of Service</Td>
          </tr>
        </table>
        {`\n`}
        <B>5. USER’S RIGHTS</B>
        {`\n \n The Users or their legal representatives, as main agents of the personal information, may exercise the following rights regarding the collection, use and sharing of personal information by the Company: \n • Request access to personal information; \n • Request corrections or erasure of personal information; \n • Users and legal representatives may access and correct their registered personal information at any time. \n • Object to processing of personal information or request temporary suspension or restriction of processing personal information; \n • Request transfer of personal information; \n • Request information, in case of automated decision-making processes, of the logic underlying the system and the importance and consequences envisaged by the processing for the User concerned; or\n • Withdraw consent. \n \n If a User contacts the Company (or personal information manager or a deputy) via electronic mail or telephone in order to exercise any of the above rights as a User, the Company will take measures without delay; provided that the Company may reject a User’s request only to the extent that there exists either a proper cause as prescribed in the laws or an equivalent cause.\n User’s withdrawal of consent will not affect the lawfulness of any processing carried out before such withdrawal of consent. If a User withdraws consent, the Company may not be able to provide certain products or services to such User. The Company will advise that User if this is the case at the time he or she withdraws consent.\n Users have right to file complaints with supervisory authority. The Company requests such Users to contact the Company before submitting any complaints with the said supervisory authority to seek a mutually acceptable solution.\n It is important that personal information the Company holds about a User is accurate and current. Users shall keep the Company informed if any of the User’s personal information changes.\n \n`}
        <B>6. User’s Obligation. </B>
        {`\n \n (1) User shall also ensure that their personal information is secure. Users shall protect personal information by choosing sufficiently strong password and keep his or her credentials and password confidential at all times. A User has obligation to not infringe a third party’s personal information. A User shall refrain from disclosing his or her personal information such as password, and from damaging a third party’s personal information or privacy. \n (2) It is important that the personal information the Company holds about a User is accurate and current. User shall inform the Company if his or her personal information changes during his or her use of the Services.\n \n `}
        <B>7. Procedure and method of destruction of personal information </B>
        {`\n \n In principle, the Company destructs the personal information immediately after the Company has achieved the purpose of its collection and use, and the period for retention described in Article 3 is expired without delay. \n If any personal information is to be retained as required by relevant laws and regulations, the Company will retain it at a separate database for the duration as required by those laws and regulations before destruction and, in such event, the personal information which is stored and managed separately will not be used for other purposes. \n The Company destructs personal information as follows: \n \n (1) Process of destruction \n \n After the purpose is achieved, the personal information provided by the User for registration for the Company’s services will be transferred to a separate database or storage location and stored for the duration as required by the Company’s internal policy or applicable laws to protect the information (please refer to the period for retention and use of personal information) before destruction. Personal information stored as set forth above will not be used for any purpose other than the specified purpose. The Company will select personal information that are subject to destruction and destroy after obtaining approval from data protection officer of the Company. \n \n (2) Method of destruction \n \n The Company destroys hard copies of personal information by shredding with a shredder or incinerating it; and delete personal information stored in the form of electronic file by using method to make that personal information cannot be restored.\n \n `}
        <B>8. Measures to protect personal information</B>
        {`\n \n In order to prevent the loss, theft, unauthorized disclosure, leakage, alteration or damage of personal information of the Users, the Company has implemented system of protection. Such measures include technical and managerial measures for security as follows:\n \n (1) Technical Measures \n (a) Protect personal information with passwords\n (b) Preventive measures against data leakage due to hacking and computer virus\n (c) Encrypt and pseudonymize certain personal information\n (d) Transmit personal information using security servers\n (2) Managerial Measures\n (a) Appoint a person in charge of protecting personal information to limit access to personal information \n (b) Provide education and training for the person in charge on a regular basis to ensure compliance with the Company’s Policy \n (c) Ensure that the Company and its personnel are in compliance with the Policy, and promptly correct any non-compliance.\n \n`}
        <B>9. Cookies and Similar Technologies </B>
        {`\n \n (1) The Company may collect collective and impersonal information through “cookies.” Cookies are very small text files to be sent to the storage device of the Users from the server used for operation of the Service. \n (2) Cookies are transferred in a format that is only readable on the website server, and will be stored under a directory of a browser used by the User. When cookies cannot be used, such as in a mobile application setting, any other technology that could perform similar functions as a cookie (e.g., advertising identifier) may be used. \n \n  Purpose of using cookies: To improve security management and Services by analyzing User’s frequency and visit time, detecting a User’s usage patterns, tracking usage traces, and identifying number of visits. \n \n (3) The Users have an option for cookie installation. Users may either allow all cookies by setting option in web browser, make each cookie checked whenever it is saved, or refuse all cookies to be saved; provided that, if a User rejects the installation of cookies, it may be difficult for such User to use services provided by the Company in whole or in part which requires signing in.\n (4) The Company improves User experience and efficiency of its Services through non-personal identification information. \n \n`}
        <B>10. Contact Information</B>
        {`\n \n (1) The Company has designated the following data protection officer who will oversee processing of personal information and handling of any complaints and damages relief of a data subject in relation to personal information: \n \n Data Protection Officer \n Name: HongMin Lee\n Position: COO\n Email: hello@handypick.io\n \n (2) Please contact the Company by contacting the data protection officer above or the department in charge of privacy protection should you have any queries in respect to this policy or wish to update your personal information. \n \n`}
        <B>11. Modification of Privacy Policy </B>
        {`\n \n The Company has the right to amend or modify this Policy from time to time and, in such case, the Company will make a public notice of it through the “Notice” of the Service by at least seven (7) days prior to implementing such modifications. \n \n`}
        <B>12. Miscellaneous </B>
        {`\n \n This Policy will not be applicable to the collection and processing of personal information performed by third party service providers, such as any websites that are linked at the Service. \n`}
      </Pre>
    </Container>
  );
};

const Container = styled.div`
  padding: 20px;
  background-color: #212432;
`;

const Title = styled.div`
  margin: 30px 0;
  color: #fff;
  font-size: 35px;
  font-weight: bold;
  padding-bottom: 35px;
  border-bottom: 1px solid #8b8b8b;
`;

const Pre = styled.pre`
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  color: #fff;
  font-family: monospace;
  line-height: 1.5;
`;

const TdTitle = styled.td`
  border: 1px black solid;
  padding: 4px;
  background-color: #4d567d;
  text-align: center;
`;

const Td = styled.td`
  border: 1px black solid;
  padding: 4px;
`;

const B = styled.span`
  font-weight: bold;
`;

const TdSubTitle = styled.td`
  border: 1px black solid;
  padding: 4px;
  width: 40%;
`;

export default PolicyE;
