import styled from "@emotion/styled";

import Card from "pages/home/c02.intro/Card";

import introBg from "assets/img/introBg.png";
import cryptoLive from "assets/img/cryptoLive.png";
import sportLive from "assets/img/sportLive.png";
import highLow from "assets/img/highLow.png";
import { keyframes } from "@emotion/react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const Intro = () => {
  const { t } = useTranslation();
  const titleRef = useRef<HTMLDivElement>(null);

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(checkIntersection, { threshold: 0.3 });

    if (titleRef.current) observer.observe(titleRef.current);

    return () => {
      if (titleRef.current) observer.unobserve(titleRef.current);
    };
  }, [titleRef]);

  const checkIntersection = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries;
    setChecked(entry.isIntersecting);
  };

  return (
    <Container bg={introBg}>
      <ContentContainer>
        <Title ref={titleRef}>
          Play{" "}
          <UnderLineContainer>
            {checked && <UnderLine />}
            Handy Pick Live
          </UnderLineContainer>
        </Title>

        <SubTitle>{t("introSubTitle")}</SubTitle>

        <CardContainer>
          <Card key={0} imgSrc={cryptoLive} title="Crypto Live" descList={[t("introCrypto1"), t("introCrypto2")]} />
          <Card key={1} imgSrc={highLow} title="Hign & Low" descList={[t("introHighLow1"), t("introHighLow2")]} />
          <Card key={2} imgSrc={sportLive} title="Sport Live" descList={[t("introSport1"), t("introSport2")]} />
        </CardContainer>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-image: url(${({ bg }: { bg: string }) => bg});
  background-size: 100vw 100vh;
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 10vw 0;
    height: auto;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1400px;
  width: 1400px;
  padding: 0 50px;

  @media (max-width: 768px) {
    padding: 0 20vw;
  }
`;

const Title = styled.div`
  font-size: 70px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: -2.8px;
  color: #ffffff;
  font-family: Pretendard;
  z-index: 2;

  @media (max-width: 1300px) {
    font-size: 5.4vw;
    letter-spacing: -0.2vw;
  }

  @media (max-width: 768px) {
    font-size: 8vw;
  }
`;

const UnderLineContainer = styled.span`
  position: relative;
  display: inline-block;
`;

const paint = keyframes`
  0%{
    width:0px;
  }

  100%{
    width:100%;
  }
`;

const UnderLine = styled.div`
  position: absolute;
  background-color: #41d1cc;
  width: 0px;
  height: 20px;
  bottom: 20px;
  z-index: -1;
  animation: ${paint} 0.5s 0.5s ease-out forwards;

  @media (max-width: 1300px) {
    height: 1.5vw;
    bottom: 1.5vw;
  }

  @media (max-width: 768px) {
    height: 2.3vw;
    bottom: 2.3vw;
  }
`;

const SubTitle = styled.div`
  margin-top: 20px;
  font-size: 28px;
  font-weight: lighter;
  letter-spacing: -1.12px;
  font-family: Pretendard;
  color: #ffffff;

  @media (max-width: 1300px) {
    margin-top: 1.5vw;
    font-size: 2.2vw;
    letter-spacing: -0.2vw;
  }

  @media (max-width: 768px) {
    margin-top: 2.3vw;
    font-size: 2.7vw;
  }
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-top: 140px;

  box-sizing: border-box;

  @media (max-width: 1300px) {
    margin-top: 10.8vw;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 13vw;
    padding: 0 20vw;
  }
`;

export default Intro;
