import { useEffect } from "react";
import styled from "@emotion/styled";

import { useTranslation } from "react-i18next";
import Footer from "components/Footer";
import PolicyK from "pages/policy/PolicyK";
import PolicyE from "pages/policy/PolicyE";
import Navbar from "components/Navbar";

const PolicyContainer = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <Container>
      <Navbar />
      <ContentContainer>{t("lang_code") === "KR" ? <PolicyK /> : <PolicyE />}</ContentContainer>
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  background-color: #212432;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentContainer = styled.div`
  margin: 100px 0;
  max-width: 1300px;
  width: 1300px;
  padding: 0 50px;
`;

export default PolicyContainer;
