import styled from "@emotion/styled";
import mainBg from "assets/img/mainBg.png";
import mainBgMobile from "assets/img/mainBgMobile.png";
import tooltipLeft from "assets/img/tooltipLeft.png";
import tooltipRight from "assets/img/tooltipRight.png";
import OctoL00 from "assets/img/octo/OctoL00.png";
import OctoL01 from "assets/img/octo/OctoL01.png";
import OctoR02 from "assets/img/octo/OctoR02.png";

import logoFillKr from "assets/img/logo_fill_KR.png";
import logoFillEn from "assets/img/logo_fill_EN.png";

import { keyframes } from "@emotion/react";
import { useEffect, useRef, useState } from "react";
import { getMdSize } from "utils/screenSize";
import Navbar from "components/Navbar";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";
import { isTopState } from "app/store";

const Main = () => {
  const { t } = useTranslation();
  const textRef = useRef<HTMLDivElement>(null);

  const setIsTop = useSetRecoilState(isTopState);
  const [checked, setChecked] = useState(false);
  const [text1, setText1] = useState("");
  const [text2, setText2] = useState("");

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, { threshold: 0.3 });

    if (textRef.current) observer.observe(textRef.current);

    return () => {
      if (textRef.current) observer.unobserve(textRef.current);
    };
  }, [textRef]);

  const callbackFunction = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries;
    setChecked(entry.isIntersecting);
    setIsTop(entry.isIntersecting);
  };

  useEffect(() => {
    setText1("");
    setText2("");
    const timer = setInterval(() => {
      typing();
    }, 200);

    const content1 = t("mainTooltip1");
    const content2 = t("mainTooltip2");
    let i = 0;
    let j = 0;

    const bigLangth = content1.length > content2.length ? content1.length : content2.length;

    function typing() {
      let txt1 = content1[i++];
      let txt2 = content2[j++];
      if (content1.length >= i) {
        setText1((prev) => `${prev}${txt1}`);
      }
      if (content2.length >= j) {
        setText2((prev) => `${prev}${txt2}`);
      }
      if (i >= bigLangth) {
        clearInterval(timer);
      }
    }

    return () => clearInterval(timer);
  }, [checked, t("lang_code")]);

  return (
    <Container bg={mainBg} bgMobile={mainBgMobile}>
      <Navbar />
      <Logo alt="logo" src={t("lang_code") === "KR" ? logoFillKr : logoFillEn} />
      <Text ref={textRef}>
        {t("mainTitle")}
        {checked && (
          <>
            <Accent1 isLangKr={t("lang_code") === "KR"} />
            <Accent2 isLangKr={t("lang_code") === "KR"} />
          </>
        )}
      </Text>

      <ImgContainer>
        <Img alt="Octo NFT" src={OctoL01} top={110} left={40} width={320} height={320} />
        <Img alt="Octo NFT" src={OctoR02} top={110} left={380} width={320} height={320} />
        <Img alt="Octo NFT" src={OctoL00} top={30} left={175} width={380} height={380} />
        <TooltipContainer top={70} left={30}>
          <Tooltip alt="tooltip" src={tooltipRight} />
          <TooltipText>
            {text1}
            <Blink>|</Blink>
          </TooltipText>
        </TooltipContainer>
        <TooltipContainer top={70} left={530}>
          <Tooltip alt="tooltip" src={tooltipLeft} />
          <TooltipText>
            {text2}
            <Blink>|</Blink>
          </TooltipText>
        </TooltipContainer>
      </ImgContainer>
    </Container>
  );
};

const Container = styled.section<{ bg: string; bgMobile: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-image: url(${({ bg }) => bg});
  background-size: cover;
  background-position: bottom center;
  overflow: hidden;

  @media (max-width: 768px) {
    background-image: url(${({ bgMobile }) => bgMobile});

    height: 150vw;
  }
`;

const Logo = styled.img`
  height: 130px;
  object-fit: contain;

  @media (max-width: 1300px) {
    height: 10vw;
  }

  @media (max-width: 768px) {
    height: 22vw;
  }
`;

const Text = styled.div`
  position: relative;
  color: #ffffff;
  font-size: 50px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: -2px;
  font-family: Pretendard;
  text-shadow: 0px 0px 4px #2a2d411f;
  z-index: 1;

  @media (max-width: 1300px) {
    font-size: 3.8vw;
    letter-spacing: -0.1vw;
  }

  @media (max-width: 768px) {
    font-size: 5.6vw;
  }
`;

const paint = (width: string) => keyframes`
  0%{
    width:0px;
  }

  100%{
    width:${width};
  }
`;

const Accent1 = styled.div<{ isLangKr: boolean }>`
  position: absolute;
  top: 10px;
  left: 0;
  width: 0px;
  height: 50px;
  background-color: #f8c53d;
  z-index: -1;
  animation: ${({ isLangKr }) => (isLangKr ? paint("87px") : paint("200px"))} 0.5s 0.5s ease-out forwards;

  @media (max-width: 1300px) {
    top: 0.8vw;
    height: 3.8vw;
    animation: ${({ isLangKr }) => (isLangKr ? paint("6.7vw") : paint("15.4vw"))} 0.5s 0.5s ease-out forwards;
  }

  @media (max-width: 768px) {
    top: 1.2vw;
    height: 5.7vw;
    animation: ${({ isLangKr }) => (isLangKr ? paint("10vw") : paint("23vw"))} 0.5s 0.5s ease-out forwards;
  }
`;

const Accent2 = styled.div<{ isLangKr: boolean }>`
  position: absolute;
  top: 10px;
  left: ${({ isLangKr }) => (isLangKr ? "229px" : "290px")};
  width: 0px;
  height: 50px;
  background-color: #f8c53d;
  z-index: -1;
  animation: ${({ isLangKr }) => (isLangKr ? paint("123px") : paint("100px"))} 0.5s 0.5s ease-out forwards;

  @media (max-width: 1300px) {
    top: 0.8vw;
    left: ${({ isLangKr }) => (isLangKr ? "17.6vw" : "22.7vw")};
    height: 3.8vw;
    animation: ${({ isLangKr }) => (isLangKr ? paint("9.5vw") : paint("7.7vw"))} 0.5s 0.5s ease-out forwards;
  }

  @media (max-width: 768px) {
    top: 1.2vw;
    left: ${({ isLangKr }) => (isLangKr ? "26.4vw" : "34vw")};
    height: 5.7vw;
    animation: ${({ isLangKr }) => (isLangKr ? paint("14.2vw") : paint("11.5vw"))} 0.5s 0.5s ease-out forwards;
  }
`;

const ImgContainer = styled.div`
  position: relative;
  width: 724px;
  height: 450px;

  @media (max-width: 1300px) {
    width: 55.7vw;
    height: 34.6vw;
  }

  @media (max-width: 768px) {
    margin-top: 5vw;
    width: 93vw;
    height: 52vw;
  }
`;

const Img = styled.img<{ width: number; height: number; top: number; left: number }>`
  position: absolute;
  object-fit: contain;
  ${({ width, height, top, left }) => `top: ${top}px; left: ${left}px; width: ${width}px; height: ${height}px;`}

  @media (max-width: 1300px) {
    ${({ width, height, top, left }) =>
      `top: ${getMdSize(top)}vw; left: ${getMdSize(left)}vw; width: ${getMdSize(width)}vw; height: ${getMdSize(
        height
      )}vw;`}
  }

  @media (max-width: 768px) {
    ${({ width, height, top, left }) =>
      `top: ${getMdSize(top * 1.5)}vw; left: ${getMdSize(left * 1.5)}vw; width: ${getMdSize(
        width * 1.7
      )}vw; height: ${getMdSize(height * 1.7)}vw;`}
  }
`;

const TooltipContainer = styled.div<{ top: number; left: number }>`
  position: absolute;

  ${({ top, left }) => `top: ${top}px; left: ${left}px;`}

  @media (max-width: 1300px) {
    ${({ top, left }) => `top: ${getMdSize(top)}vw; left: ${getMdSize(left)}vw;`}
  }

  @media (max-width: 768px) {
    ${({ top, left }) => `top: ${getMdSize(top * 1.5)}vw; left: ${getMdSize(left * 1.5)}vw;`}
  }
`;

const Tooltip = styled.img`
  width: 190px;
  height: 100px;

  @media (max-width: 1300px) {
    width: 14.6vw;
    height: 7.7vw;
  }

  @media (max-width: 768px) {
    width: 21.9vw;
    height: 11.5vw;
  }
`;

const TooltipText = styled.div`
  position: absolute;
  top: 27px;
  left: 24px;
  text-align: center;
  width: 142px;
  color: #161a1d;
  line-height: 1.2;
  letter-spacing: -0.8px;
  white-space: pre-wrap;
  font-family: Pretendard;

  @media (max-width: 1300px) {
    top: 2.1vw;
    left: 1.8vw;
    width: 10.9vw;
    font-size: 1.3vw;
  }

  @media (max-width: 768px) {
    top: 3.2vw;
    left: 2.7vw;
    width: 16.4vw;
    font-size: 2vw;
  }
`;

const blinkCaret = keyframes`
    from, to { opacity: 0 }
    50% { opacity: 1; }
`;

const Blink = styled.span`
  margin-left: 1px;
  animation: ${blinkCaret} 0.75s step-end infinite;
`;

export default Main;
