import styled from "@emotion/styled";

type OctoImageProps = {
  name: string;
  img: string;
};

const OctoImage = ({ name, img }: OctoImageProps) => {
  return (
    <Container>
      <Name>{name}</Name>
      <Octo alt="octo img" src={img} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 78px;
  padding-left: 35px;
  padding-right: 10px;
  border: 2px solid #ffffff;
  border-radius: 100px;

  @media (max-width: 1300px) {
    height: 6vw;
    padding-left: 2.7vw;
    padding-right: 0.8vw;
    border-radius: 7.7vw;
  }
`;

const Name = styled.div`
  font-size: 39px;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: -2.6px;
  color: #ffffff;
  font-family: Pretendard;
  white-space: nowrap;

  @media (max-width: 1300px) {
    font-size: 3vw;
    letter-spacing: -0.2vw;
  }
`;

const Octo = styled.img`
  width: 65px;
  height: 65px;
  object-fit: contain;

  @media (max-width: 1300px) {
    width: 5vw;
    height: 5vw;
  }
`;

export default OctoImage;
