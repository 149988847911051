import { BrowserRouter, Route, Routes } from "react-router-dom";

import PolicyContainer from "pages/policy/PolicyContainer";
import PolicyE from "pages/policy/PolicyE";
import PolicyK from "pages/policy/PolicyK";
import TermsContainer from "pages/terms/TermsContainer";
import TermsE from "pages/terms/TermsE";
import TermsK from "pages/terms/TermsK";
import Home from "./pages/home/Home";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/policy" element={<PolicyContainer />} />
        <Route path="/terms" element={<TermsContainer />} />

        {/* for app */}
        <Route path="/policy/kr" element={<PolicyK />} />
        <Route path="/policy/en" element={<PolicyE />} />
        <Route path="/terms/kr" element={<TermsK />} />
        <Route path="/terms/en" element={<TermsE />} />
        {/* for app */}
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
