import styled from "@emotion/styled";

type CardProps = {
  imgSrc: string;
  title: string;
  descList: string[];
};

const Card = ({ imgSrc, title, descList }: CardProps) => {
  return (
    <Container>
      <Img alt="content image" src={imgSrc} />
      <Title>{title}</Title>
      <DescContainer>
        <ul style={{ listStyle: "disc" }}>
          {descList.map((desc, idx) => (
            <Desc key={idx}>{desc}</Desc>
          ))}
        </ul>
      </DescContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 286px;

  @media (max-width: 1300px) {
    width: 22vw;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Img = styled.img`
  width: 300px;
  height: 230px;
  object-fit: contain;

  @media (max-width: 1300px) {
    width: 23.1vw;
    height: 17.7vw;
  }

  @media (max-width: 768px) {
    width: 56vw;
    height: 37vw;
  }
`;

const Title = styled.div`
  margin-top: 29px;
  font-size: 35px;
  font-weight: bold;
  color: #ffffff;
  font-family: Pretendard;

  @media (max-width: 1300px) {
    margin-top: 2.2vw;
    font-size: 2.7vw;
  }

  @media (max-width: 768px) {
    margin-top: 3.3vw;
    font-size: 7vw;
  }
`;

const DescContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;

  @media (max-width: 1300px) {
    margin-top: 0.9vw;
  }

  @media (max-width: 768px) {
    margin-top: 4vw;
  }
`;

const Desc = styled.li`
  margin-top: 8px;
  text-align: left;
  font-size: 18px;
  font-weight: lighter;
  letter-spacing: -0.72px;
  color: #ffffff;
  line-height: 1.3;
  margin-left: 20px;
  font-family: Pretendard;

  @media (max-width: 1300px) {
    margin-top: 0.6vw;
    font-size: 1.4vw;
  }

  @media (max-width: 768px) {
    margin-top: 1vw;
    font-size: 3vw;
  }
`;

export default Card;
