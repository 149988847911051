import { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";

import MainCalousel from "pages/home/c05.links/MainCalousel";
import Btn from "components/Btn";

import { keyframes } from "@emotion/react";
import { useTranslation } from "react-i18next";

import { getMdSize } from "utils/screenSize";
import { APPSTORE_URL, PLAYSTORE_URL } from "config";
import OctoL03 from "assets/img/octo/OctoL03.png";
import OctoR04 from "assets/img/octo/OctoR04.png";
import OctoL05 from "assets/img/octo/OctoL05.png";
import linksBg from "assets/img/linksBg.png";
import phoneCase from "assets/img/phone_case.png";
import appStore from "assets/img/icons/app_down_appstore.png";
import playStore from "assets/img/icons/app_down_playstore.png";

const Links = () => {
  const { t } = useTranslation();
  const subTitleRef = useRef<HTMLDivElement | null>(null);

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(checkIntersection, { threshold: 0.3 });

    if (subTitleRef.current) observer.observe(subTitleRef.current);

    return () => {
      if (subTitleRef.current) observer.unobserve(subTitleRef.current);
    };
  }, [subTitleRef]);

  const checkIntersection = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries;
    setChecked(entry.isIntersecting);
  };

  return (
    <Container bg={linksBg}>
      <ContentContainer>
        <TextContainer>
          <SubTitle ref={subTitleRef}>
            <UnderLineContainer>
              {checked && <UnderLine />}
              {t("linksSubtitle")}
            </UnderLineContainer>
          </SubTitle>
          <Title>Let's Play Now!</Title>
          <BtnContainer isMobileVisible={false}>
            <a href={PLAYSTORE_URL} target="_blank">
              <Img alt="link icon" src={playStore} />
            </a>
            <a href={APPSTORE_URL} target="_blank">
              <Img alt="link icon" src={appStore} />
            </a>
          </BtnContainer>
        </TextContainer>
        <CarouselContainer>
          <MainCalousel />
          <PhoneCase src={phoneCase} alt="맞춰볼래" />
          <OctoImg alt="octo img" src={OctoL03} top={-100} right={-100} />
          <OctoImg alt="octo img" src={OctoR04} bottom={200} right={-140} />
          <OctoImg alt="octo img" src={OctoL05} bottom={100} left={-140} />
        </CarouselContainer>
        <BtnContainer isMobileVisible={true}>
          <a href={PLAYSTORE_URL} target="_blank">
            <Img alt="link icon" src={playStore} />
          </a>
          <a href={APPSTORE_URL} target="_blank">
            <Img alt="link icon" src={appStore} />
          </a>
        </BtnContainer>
      </ContentContainer>
    </Container>
  );
};
const Container = styled.div<{ bg: string }>`
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-image: url(${({ bg }) => bg});
  background-size: 100vw 100vh;
  overflow: hidden;

  @media (max-width: 768px) {
    height: auto;
    background-size: cover;
    background-position: center;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  width: 1400px;
  padding: 0 50px;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    padding: 15vw 5vw;
  }
`;

const TextContainer = styled.div`
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10vw;
  }
`;

const SubTitle = styled.div`
  position: relative;
  font-size: 30px;
  letter-spacing: -1.2px;
  font-family: Pretendard;
  color: #fff;
  z-index: 6;

  @media (max-width: 1300px) {
    font-size: 2.3vw;
    letter-spacing: -0.1vw;
  }

  @media (max-width: 768px) {
    font-size: 5vw;
  }
`;

const UnderLineContainer = styled.span`
  position: relative;
  display: inline-block;
`;

const paint = keyframes`
  0%{
    width:0px;
  }

  100%{
    width:100%;
  }
`;

const UnderLine = styled.div`
  position: absolute;
  background-color: #41d1cc;
  width: 0px;
  height: 10px;
  bottom: 0;
  z-index: -1;
  animation: ${paint} 0.5s 0.5s ease-out forwards;

  @media (max-width: 1300px) {
    height: 0.8vw;
  }
`;

const Title = styled.div`
  font-size: 75px;
  font-weight: bold;
  line-height: 1.5;
  font-family: Pretendard;
  letter-spacing: -3.75px;
  color: #ffffff;

  @media (max-width: 1300px) {
    font-size: 5.7vw;
    letter-spacing: -0.3vw;
  }

  @media (max-width: 768px) {
    font-size: 10vw;
  }
`;

const BtnContainer = styled.div<{ isMobileVisible: boolean }>`
  display: ${({ isMobileVisible }) => (!isMobileVisible ? "flex" : "none")};
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    display: ${({ isMobileVisible }) => (isMobileVisible ? "flex" : "none")};
    gap: 2vw;
  }
`;

const CarouselContainer = styled.div`
  position: relative;
  left: -162.8px;
  width: 267px;

  @media (max-width: 1300px) {
    left: -12.5vw;
    width: 20.5vw;
  }

  @media (max-width: 768px) {
    left: 0;
    width: 29vw;
  }
`;

const PhoneCase = styled.img`
  position: relative;
  left: -128.6px;
  height: 700px;
  z-index: 10;

  @media (max-width: 1300px) {
    left: -9.9vw;
    height: 53.85vw;
  }

  @media (max-width: 768px) {
    left: -13.5vw;
    height: 75vw;
  }
`;

const OctoImg = styled.img<{ top?: number; left?: number; bottom?: number; right?: number }>`
  position: absolute;
  width: 180px;
  height: 180px;
  object-fit: contain;

  ${({ top, bottom, left, right }) =>
    `${top ? `top:${top}px;` : ""} ${left ? `left:${left}px;` : ""} ${bottom ? `bottom: ${bottom}px;` : ""} ${
      right ? `right: ${right}px;` : ""
    }`}

  @media (max-width: 1300px) {
    width: 13.8vw;
    height: 13.8vw;

    ${({ top, bottom, left, right }) =>
      `${top ? `top:${getMdSize(top)}vw;` : ""} ${left ? `left:${getMdSize(left)}vw;` : ""} ${
        bottom ? `bottom: ${getMdSize(bottom)}vw;` : ""
      } ${right ? `right: ${getMdSize(right)}vw;` : ""}`}
  }

  @media (max-width: 768px) {
    width: 20vw;
    height: 20vw;

    ${({ top, bottom, left, right }) =>
      `${top ? `top:${getMdSize(top) * 1.5}vw;` : ""} ${left ? `left:${getMdSize(left) * 1.5}vw;` : ""} ${
        bottom ? `bottom: ${getMdSize(bottom) * 1.5}vw;` : ""
      } ${right ? `right: ${getMdSize(right) * 1.5}vw;` : ""}`}
  }
`;

const Img = styled.img`
  width: 220px;
  height: 70px;
  margin-top: 30px;
  cursor: pointer;

  transform: scale(1);
  transition: transform 0.35s ease-out 100ms;

  :hover {
    transform: scale(1.05);
  }

  @media (max-width: 1300px) {
    width: 16.9vw;
    height: 5.4vw;
    margin-top: 2.3vw;
  }

  @media (max-width: 768px) {
    width: 30vw;
    height: 10vw;
  }
`;

export default Links;
