import { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";

import { keyframes } from "@emotion/react";

import OctoL00 from "assets/img/octo/OctoL00.png";
import OctoL01 from "assets/img/octo/OctoL01.png";
import OctoL02 from "assets/img/octo/OctoL02.png";
import OctoL03 from "assets/img/octo/OctoL03.png";
import OctoL04 from "assets/img/octo/OctoL04.png";
import OctoL05 from "assets/img/octo/OctoL05.png";
import OctoImage from "pages/home/c03.octo/OctoImage";

const OctoList = () => {
  const leftRef = useRef<HTMLDivElement | null>(null);
  const rightRef = useRef<HTMLDivElement | null>(null);

  const [leftWidth, setLeftWidth] = useState(0);
  const [rightWidth, setRightWidth] = useState(0);

  useEffect(() => {
    setLeftWidth(leftRef.current?.scrollWidth ? leftRef.current?.scrollWidth : 0);
    setRightWidth(rightRef.current?.scrollWidth ? rightRef.current?.scrollWidth : 0);
  }, [leftRef, rightRef]);

  return (
    <Container>
      <BillboardContainer>
        <Billboard ref={leftRef} width={leftWidth} isLeft={true}>
          <OctoImage name={"Astronaut"} img={OctoL00} />
          <OctoImage name={"Student"} img={OctoL01} />
          <OctoImage name={"Gentleman"} img={OctoL02} />
          <OctoImage name={"Baseball player"} img={OctoL03} />
          <OctoImage name={"Traveler"} img={OctoL04} />
          <OctoImage name={"Gunbam Jangsoo"} img={OctoL05} />
          <OctoImage name={"Astronaut"} img={OctoL00} />
          <OctoImage name={"Student"} img={OctoL01} />
          <OctoImage name={"Gentleman"} img={OctoL02} />
          <OctoImage name={"Baseball player"} img={OctoL03} />
          <OctoImage name={"Traveler"} img={OctoL04} />
          <OctoImage name={"Gunbam Jangsoo"} img={OctoL05} />
          <OctoImage name={"Astronaut"} img={OctoL00} />
          <OctoImage name={"Student"} img={OctoL01} />
          <OctoImage name={"Gentleman"} img={OctoL02} />
          <OctoImage name={"Baseball player"} img={OctoL03} />
          <OctoImage name={"Traveler"} img={OctoL04} />
          <OctoImage name={"Gunbam Jangsoo"} img={OctoL05} />
          <OctoImage name={"Astronaut"} img={OctoL00} />
          <OctoImage name={"Student"} img={OctoL01} />
          <OctoImage name={"Gentleman"} img={OctoL02} />
          <OctoImage name={"Baseball player"} img={OctoL03} />
          <OctoImage name={"Traveler"} img={OctoL04} />
          <OctoImage name={"Gunbam Jangsoo"} img={OctoL05} />
        </Billboard>

        <Billboard ref={rightRef} width={rightWidth} isLeft={false}>
          <OctoImage name={"Astronaut"} img={OctoL00} />
          <OctoImage name={"Student"} img={OctoL01} />
          <OctoImage name={"Gentleman"} img={OctoL02} />
          <OctoImage name={"Baseball player"} img={OctoL03} />
          <OctoImage name={"Traveler"} img={OctoL04} />
          <OctoImage name={"Gunbam Jangsoo"} img={OctoL05} />
          <OctoImage name={"Astronaut"} img={OctoL00} />
          <OctoImage name={"Student"} img={OctoL01} />
          <OctoImage name={"Gentleman"} img={OctoL02} />
          <OctoImage name={"Baseball player"} img={OctoL03} />
          <OctoImage name={"Traveler"} img={OctoL04} />
          <OctoImage name={"Gunbam Jangsoo"} img={OctoL05} />
          <OctoImage name={"Astronaut"} img={OctoL00} />
          <OctoImage name={"Student"} img={OctoL01} />
          <OctoImage name={"Gentleman"} img={OctoL02} />
          <OctoImage name={"Baseball player"} img={OctoL03} />
          <OctoImage name={"Traveler"} img={OctoL04} />
          <OctoImage name={"Gunbam Jangsoo"} img={OctoL05} />
          <OctoImage name={"Astronaut"} img={OctoL00} />
          <OctoImage name={"Student"} img={OctoL01} />
          <OctoImage name={"Gentleman"} img={OctoL02} />
          <OctoImage name={"Baseball player"} img={OctoL03} />
          <OctoImage name={"Traveler"} img={OctoL04} />
          <OctoImage name={"Gunbam Jangsoo"} img={OctoL05} />
        </Billboard>
      </BillboardContainer>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  bottom: 30px;
  width: 100vw;
`;

const BillboardContainer = styled.div`
  width: 100vw;
  overflow: hidden;
  margin-top: 40px;
`;

const turnLeft = keyframes`
  0% {
    transform: translateX(0%);
  }
  100%{
    transform: translateX(-50%);
  }
`;

const turnRight = keyframes`
  0% {
    transform: translateX(-50%);
  }
  100%{
    transform: translateX(0%);
  }
`;

const Billboard = styled.div<{ width: number; isLeft: boolean }>`
  display: flex;
  align-items: center;
  gap: 40px;
  margin-top: 13px;
  padding: 2px;
  width: ${({ width }) => `${width}px;`};
  animation: ${({ isLeft }) => (isLeft ? turnLeft : turnRight)} linear 60s infinite;

  @media (max-width: 1300px) {
    gap: 3.1vw;
    margin-top: 1vw;
  }
`;

export default OctoList;
