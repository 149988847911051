import Router from "./Router";

import { RecoilRoot } from "recoil";
import "language/i18n";

function App() {
  return (
    <RecoilRoot>
      <Router />
    </RecoilRoot>
  );
}

export default App;
