import { atom } from "recoil";

export const scrollState = atom({
  key: "scroll",
  default: {
    lastScrollTime: 0,
    prevIdx: 0,
    currIdx: 0,
  },
});

export const scrollListState = atom({
  key: "scrollList",
  default: [0, 0, 0, 0, 0, 0],
});

export const isTopState = atom({
  key: "isTop",
  default: false,
});
