import React, { useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";

import { keyframes } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

import logoFillKR from "assets/img/logo_fill_KR.png";
import logoFillEN from "assets/img/logo_fill_EN.png";
import appStore from "assets/img/icons/app_down_appstore2.png";
import playStore from "assets/img/icons/app_down_playstore2.png";
import { APPSTORE_URL, PLAYSTORE_URL, WHITEPAPER_EN, WHITEPAPER_KR } from "../config";

type SidebarProps = {
  setIsActiveSidebar: React.Dispatch<React.SetStateAction<boolean>>;
};

const Sidebar = ({ setIsActiveSidebar }: SidebarProps) => {
  const { t, i18n } = useTranslation();

  const [visible, setVisible] = useState(true);

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth > 768) setIsActiveSidebar(false);
    });

    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, []);

  const closeSidebar = async () => {
    setVisible(false);
    await new Promise((resolve) => {
      setTimeout(resolve, 800);
    });
    setIsActiveSidebar(false);
  };

  const toggleLocales = useCallback(
    (locale: string) => {
      i18n.changeLanguage(locale);
    },
    [i18n]
  );

  return (
    <Container>
      <BgContainer visible={visible} />
      <CloseArea onClick={closeSidebar} />
      <ContentContainer visible={visible}>
        <div onClick={closeSidebar}>
          <CloseIcon sx={{ position: "absolute", top: "23px", right: "23px" }} />
        </div>

        <Logo src={t("lang_code") === "KR" ? logoFillKR : logoFillEN} />

        <LangContainer>
          <LangText isSelected={t("lang_code") === "KR"} onClick={() => toggleLocales("ko")}>
            KOR
          </LangText>
          <LangText isSelected={false}>|</LangText>
          <LangText isSelected={t("lang_code") === "EN"} onClick={() => toggleLocales("en")}>
            ENG
          </LangText>
        </LangContainer>

        <Anchor
          href={t("lang_code") === "KR" ? WHITEPAPER_KR : WHITEPAPER_EN}
          target="_blank"
          rel="noopener noreferrer"
        >
          <WhitePaper>Whitepaper</WhitePaper>
        </Anchor>

        <LinkContainer>
          <LinkTitle>App Download</LinkTitle>
          <a href={PLAYSTORE_URL} target="_blank">
            <LinkImg src={playStore} />
          </a>
          <a href={APPSTORE_URL} target="_blank">
            <LinkImg src={appStore} />
          </a>
        </LinkContainer>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
`;

const opacityActivate = keyframes`
    0% {
      opacity:0;
    }
  
    100%{
      opacity:1;
    }
  `;

const opacityDeactivate = keyframes`
    0% {
      opacity:1;
    }
  
    100%{
      opacity:0;
    }
  `;

const BgContainer = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  animation: ${({ visible }) => (visible ? opacityActivate : opacityDeactivate)} 0.4s forwards;
`;

const slideActivate = keyframes`
  0% {
    right:-85vw;
  }

  100%{
    right:0;
  }
`;

const slideDeactivate = keyframes`
  0% {
    right:0;
  }

  100%{
    right:-85vw;
  }
`;

const ContentContainer = styled.div<{ visible: boolean }>`
  position: absolute;
  right: 0;
  width: 85vw;
  height: 100vh;
  background-color: #fff;
  z-index: 101;

  animation: ${({ visible }) => (visible ? slideActivate : slideDeactivate)} 0.8s forwards;
`;

const CloseArea = styled.div`
  position: absolute;
  left: 0;
  width: 15vw;
  height: 100vh;
`;

const Logo = styled.img`
  width: 128px;
  height: 50px;
  padding: 23px;
  box-sizing: content-box;
  object-fit: contain;
`;

const LangContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 23px;
`;

const LangText = styled.div<{ isSelected: boolean }>`
  color: ${({ isSelected }) => (isSelected ? "#363636" : "#777777")};
  font-family: Spoqa Han Sans;
  font-weight: 900;
`;

const WhitePaper = styled.div`
  padding: 23px;
  color: #363636;
  font-family: Spoqa Han Sans;
  font-weight: 900;

  :hover {
    color: #fd9b27;
  }
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin: 23px;
  padding-top: 45px;
  border-top: 1px solid black;
`;

const LinkTitle = styled.div`
  color: #363636;
  font-family: Spoqa Han Sans;
  font-weight: 700;
`;

const LinkImg = styled.img`
  height: 44px;
  object-fit: contain;
`;

const Anchor = styled.a`
  text-decoration: none;
`;

export default Sidebar;
