import i18n, { Resource } from "i18next";
import { initReactI18next } from "react-i18next";

import * as en from "./lang_en.json";
import * as ko from "./lang_ko.json";

const resources: Resource = {
  "en": {
    translation: {
        ...en
    }
  },
  "ko": {
    translation: {
        ...ko
    }
  }
} as const;

i18n
    .use(initReactI18next)
    .init({
        resources: resources,
        lng: "ko",
        fallbackLng: {
            "en": ["en"],
            default: ["ko"]
        },
        debug: true,
        keySeparator: false,
        interpolation: {
            escapeValue: false
        },
        react: {
            useSuspense: false
        }
});

export default i18n;