import { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

import { useTranslation } from "react-i18next";

import phoneKR1 from "assets/img/app_img/m1_1_KR.png";
import phoneKR2 from "assets/img/app_img/m1_2_KR.png";
import phoneKR3 from "assets/img/app_img/m1_3_KR.png";
import phoneEN1 from "assets/img/app_img/m1_1_EN.png";
import phoneEN2 from "assets/img/app_img/m1_2_EN.png";
import phoneEN3 from "assets/img/app_img/m1_3_EN.png";

const MainCalousel = () => {
  const { t } = useTranslation();
  const slide = useRef<HTMLUListElement>(null);
  const [slideIdx, setSlideIdx] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setSlideIdx((prev) => {
        if (slide.current && prev >= slide.current.children.length - 2) {
          return 0;
        } else {
          return prev + 1;
        }
      });
    }, 1500);

    return () => clearInterval(timer);
  }, []);

  return (
    <Container>
      <CarouselList ref={slide} step={slideIdx}>
        <li>
          <Img src={t("lang_code") === "KR" ? phoneKR1 : phoneEN1} alt="home page" />
        </li>
        <li>
          <Img src={t("lang_code") === "KR" ? phoneKR2 : phoneEN2} alt="sport live page" />
        </li>
        <li>
          <Img src={t("lang_code") === "KR" ? phoneKR3 : phoneEN3} alt="sport live page" />
        </li>
        <li>
          <Img src={t("lang_code") === "KR" ? phoneKR1 : phoneEN1} alt="home page" />
        </li>
      </CarouselList>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: 0;
  width: 267px;
  height: 700px;
  overflow: hidden;
  z-index: 9;

  @media (max-width: 1300px) {
    width: 20.5vw;
    height: 53.8vw;
  }

  @media (max-width: 768px) {
    width: 29vw;
    height: 75vw;
  }
`;

const slideAni = (step: number) => keyframes`
  0% {
    left: ${step * -267}px;
  }
  100% {
    left: ${(step + 1) * -267}px;
  }
  `;

const slideAniMiddle = (step: number) => keyframes`
  0% {
    left: ${step * -20.5}vw;
  }
  100% {
    left: ${(step + 1) * -20.5}vw;
  }
  `;

const slideAniMobile = (step: number) => keyframes`
  0% {
    left: ${step * -29}vw;
  }
  100% {
    left: ${(step + 1) * -29}vw;
  }
  `;

const CarouselList = styled.ul<{ step: number }>`
  position: absolute;
  top: 52px;
  display: flex;

  left: ${({ step }) => `${step * -267}px`};
  animation: ${({ step }) => slideAni(step)} 1s 0.5s forwards;

  @media (max-width: 1300px) {
    top: 4vw;
    left: ${({ step }) => `${step * -20.5}vw`};
    animation: ${({ step }) => slideAniMiddle(step)} 1s 0.5s forwards;
  }

  @media (max-width: 768px) {
    top: 6vw;
    left: ${({ step }) => `${step * -29}vw`};
    animation: ${({ step }) => slideAniMobile(step)} 1s 0.5s forwards;
  }
`;

const Img = styled.img`
  height: 580px;
  width: 267px;

  @media (max-width: 1300px) {
    height: 44.6vw;
    width: 20.5vw;
  }

  @media (max-width: 768px) {
    height: 63vw;
    width: 29vw;
  }
`;

export default MainCalousel;
