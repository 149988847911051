import { useEffect } from "react";
import styled from "@emotion/styled";

import LayoutContainer from "pages/home/components/LayoutContainer";

import { useRecoilValue, useSetRecoilState } from "recoil";

import { isTopState, scrollListState, scrollState } from "app/store";

const Home = () => {
  const setScroll = useSetRecoilState(scrollState);
  const scrollList = useRecoilValue(scrollListState);
  const isTop = useRecoilValue(isTopState);

  useEffect(() => {
    setScroll((prev) => ({ prevIdx: prev.currIdx, currIdx: 0, lastScrollTime: new Date().getTime() }));
    window.addEventListener("wheel", checkWheel);

    return () => window.removeEventListener("wheel", checkWheel);
  }, []);

  function checkWheel(e: any) {
    const now = new Date().getTime();
    const freezeTime = 1000;
    const maxIdx = scrollList.length - 1;

    if (e.deltaY >= 0) {
      setScroll((prev) => {
        if (prev.lastScrollTime + freezeTime >= now) return prev; // 값이 바뀌고 일정시간 변경 못하도록
        if (prev.currIdx >= maxIdx) return prev;
        return { lastScrollTime: now, prevIdx: prev.currIdx, currIdx: prev.currIdx + 1 };
      });
    } else {
      setScroll((prev) => {
        if (prev.lastScrollTime + freezeTime >= now) return prev;
        if (prev.currIdx <= 0) return prev;
        return { lastScrollTime: now, prevIdx: prev.currIdx, currIdx: prev.currIdx - 1 };
      });
    }
  }
  return (
    <Container>
      <LayoutContainer />

      <MoveTopContainer isTop={isTop}>
        <MoveTop
          onClick={() =>
            setScroll((prev) => ({ prevIdx: prev.currIdx, currIdx: 0, lastScrollTime: new Date().getTime() }))
          }
        >
          TOP
        </MoveTop>
      </MoveTopContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  @media (max-width: 768px) {
    height: auto;
    overflow: auto;
  }
`;

const MoveTopContainer = styled.div<{ isTop: boolean }>`
  position: absolute;
  right: -60px;
  bottom: 30vh;
  transition: transform 1s ease;
  transform: translateX(${({ isTop }) => (isTop ? "0px" : "-60px")});
  @media (max-width: 768px) {
    display: none;
  }
`;

const MoveTop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  font-family: Pretendard;
  background-color: white;
  border-radius: 10px 0px 0px 10px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
`;

export default Home;
