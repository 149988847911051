import styled from "@emotion/styled";

import OctoText from "pages/home/c03.octo/OctoText";
import OctoList from "pages/home/c03.octo/OctoList";

import octoBg from "assets/img/octoBg.png";
import OctoL00 from "assets/img/octo/OctoL00.png";

import OctoR05 from "assets/img/octo/OctoR05.png";
import { useTranslation } from "react-i18next";

const Octo = () => {
  const { t } = useTranslation();

  return (
    <Container bg={octoBg}>
      <ContentContainer>
        <Title>{t("octoTitle")}</Title>
        <TextContainer>
          <OctoText
            title={t("octo1Title")}
            subTitle={t("octo1SubTitle")}
            desc={t("octo1Desc")}
            hashTag={t("octo1Hashtag")}
            align="left"
            octoImg={OctoR05}
          />
          <OctoText
            title={t("octo2Title")}
            subTitle={t("octo2SubTitle")}
            desc={t("octo2Desc")}
            hashTag={t("octo2Hashtag")}
            align="right"
            octoImg={OctoL00}
          />
        </TextContainer>
        <OctoList />
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-image: url(${({ bg }: { bg: string }) => bg});
  background-size: 100vw 100vh;
  overflow: hidden;
  @media (max-width: 768px) {
    background-size: cover;
    height: auto;
  }
`;

const ContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1400px;
  width: 1400px;
  padding: 50px;

  @media (max-width: 768px) {
    padding: 0 10vw;
  }
`;

const Title = styled.h1`
  text-align: center;
  font-family: Pretendard;
  font-size: 70px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: -2.8px;
  color: #ffffff;

  @media (max-width: 1300px) {
    font-size: 5.4vw;
    letter-spacing: -0.2vw;
  }

  @media (max-width: 768px) {
    margin-top: 15vw;
    font-size: 8vw;
  }
`;

const TextContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 200px;

  @media (max-width: 768px) {
    align-items: left;
    align-content: cneter;
    gap: 10vw;
    margin-top: 10vw;
    margin-bottom: 40vw;
    box-sizing: border-box;
  }
`;

export default Octo;
