export const APPSTORE_URL = "https://apps.apple.com/kr/app/Handypick/id1546016518";

export const PLAYSTORE_URL = "https://play.google.com/store/apps/details?id=io.handypick.app";

export const TWITER_URL = "https://twitter.com/HandyProject";

export const TELEGRAM_KR_URL = "https://t.me/HandyOfficial";
export const TELEGRAM_EN_URL = "https://t.me/HandyOfficialGroup";

export const NAVER_URL = "https://post.naver.com/my.naver?memberNo=52183481&navigationType=push";

export const FACEBOOK_URL = "https://www.facebook.com/handylabs.io";

export const MEDIUM_URL = "https://medium.com/handy-project/guideline/home";

export const WHITEPAPER_KR = "/assets/whitepaper/HANDY_Whitepaper_v2.2_KR.pdf";
export const WHITEPAPER_EN = "/assets/whitepaper/HANDY_Whitepaper_v2.2_EN.pdf";
