import styled from "@emotion/styled";

import { useTranslation } from "react-i18next";

import logoKR from "assets/img/logo_KR.png";
import logoEN from "assets/img/logo_EN.png";

import twiter from "assets/img/icons/sns_twi.png";
import telegram from "assets/img/icons/sns_tel.png";
import naver from "assets/img/icons/sns_naver.png";
import facebook from "assets/img/icons/sns_facebook.png";
import medium from "assets/img/icons/sns_medium.png";
import { FACEBOOK_URL, MEDIUM_URL, NAVER_URL, TELEGRAM_EN_URL, TELEGRAM_KR_URL, TWITER_URL } from "config";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { scrollState } from "app/store";

const Footer = () => {
  const { t } = useTranslation();

  const setScroll = useSetRecoilState(scrollState);

  return (
    <Container>
      <MaxWidth>
        <Link
          to="/"
          style={{ textDecoration: "none" }}
          onClick={() =>
            setScroll((prev) => ({ prevIdx: prev.currIdx, currIdx: 0, lastScrollTime: new Date().getTime() }))
          }
        >
          <Logo src={t("lang_code") === "KR" ? logoKR : logoEN} alt="handypick" />
        </Link>
        <MenuContainer className="footer_menu">
          <Link style={{ textDecoration: "none" }} to="/policy">
            <Menu>{t("privacyPolicy")}</Menu>
          </Link>
          <Link style={{ textDecoration: "none" }} to="/terms">
            <Menu>{t("termsUse")}</Menu>
          </Link>
        </MenuContainer>

        <SnsContainer>
          <List>
            <Anchor href={TWITER_URL} target="_blank" rel="noopener noreferrer">
              <Img src={twiter} alt="twitter community" />
            </Anchor>
          </List>
          {t("lang_code") === "KR" ? (
            <Fragment>
              <List>
                <Anchor href={TELEGRAM_KR_URL} target="_blank" rel="noopener noreferrer">
                  <Img src={telegram} alt="telegram community" />
                </Anchor>
              </List>
              <List>
                <Anchor href={NAVER_URL} target="_blank" rel="noopener noreferrer">
                  <Img src={naver} alt="naver community" />
                </Anchor>
              </List>
            </Fragment>
          ) : (
            <Fragment>
              <List>
                <Anchor href={TELEGRAM_EN_URL} target="_blank" rel="noopener noreferrer">
                  <Img src={telegram} alt="telegram community" />
                </Anchor>
              </List>
              <List>
                <Anchor href={MEDIUM_URL} target="_blank" rel="noopener noreferrer">
                  <Img src={medium} alt="medium community" />
                </Anchor>
              </List>
            </Fragment>
          )}
          <List>
            <Anchor href={FACEBOOK_URL} target="_blank" rel="noopener noreferrer">
              <Img src={facebook} alt="facebook community" />
            </Anchor>
          </List>
        </SnsContainer>

        <div className="split row3_2">
          <Text className="company_addr">hello@handypick.io</Text>
          {t("lang_code") === "KR" && (
            <Text className="company_email">통신판매신고번호: 제 2020-서울강남-03665 호</Text>
          )}
          <Text className="company_email">@Copyright by Handy Labs Limited All Rights Reserved</Text>
        </div>

        <div className="col3"></div>
      </MaxWidth>
    </Container>
  );
};

const Container = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 130px;
  background-color: #1b1d22;

  @media (max-width: 768px) {
    padding-top: 50px;
  }
`;

const MaxWidth = styled.div`
  max-width: 1400px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 50px;

  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

const Logo = styled.img`
  height: 40px;
  object-fit: contain;
`;

const MenuContainer = styled.ul`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 40px 0;
  border-bottom: 1px solid #c9c9c9;
`;

const Menu = styled.div`
  color: #fff;
  font-size: 22px;
  font-weight: bold;
  text-decoration: none;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const SnsContainer = styled.ul`
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 47px;
  margin-bottom: 20px;
  gap: 20px;

  @media (max-width: 768px) {
    margin-top: 30px;
    margin-bottom: 12px;
  }
`;

const Text = styled.div`
  color: #fff;
  line-height: 1.5;

  @media (max-width: 768px) {
    font-size: 13px;
  }
`;

const Img = styled.img`
  width: 48px;
  height: 48px;
  object-fit: contain;
  cursor: pointer;
  z-index: 10;
  @media (max-width: 768px) {
    width: 44px;
    height: 44px;
  }
`;

const Anchor = styled.a`
  cursor: pointer;
  z-index: 10;
`;

const List = styled.li`
  width: 48px;
  height: 48px;
  z-index: 10;
  cursor: pointer;
`;

export default Footer;
