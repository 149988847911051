import { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";

import { keyframes } from "@emotion/react";

type OctoTextProps = {
  title: string;
  subTitle: string;
  desc: string;
  hashTag: string;
  align: "left" | "right";
  octoImg: string;
};

const OctoText = ({ title, subTitle, desc, hashTag, align, octoImg }: OctoTextProps) => {
  const titleRef = useRef<HTMLDivElement | null>(null);
  const hashTagRef = useRef<HTMLDivElement | null>(null);

  const [hashTagWidth, setHashTagWidth] = useState(0);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    checkHashTagWidth();
    window.addEventListener("resize", checkHashTagWidth);
    return () => window.removeEventListener("resize", checkHashTagWidth);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(checkIntersection, { threshold: 0.3 });

    if (titleRef.current) observer.observe(titleRef.current);

    return () => {
      if (titleRef.current) observer.unobserve(titleRef.current);
    };
  }, [titleRef]);

  function checkHashTagWidth() {
    const _hashTagWidth = hashTagRef.current?.offsetWidth;
    if (_hashTagWidth) {
      setHashTagWidth(_hashTagWidth);
    }
  }

  const checkIntersection = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries;
    setChecked(entry.isIntersecting);
  };

  return (
    <Container>
      {align === "left" && <OctoImg alt="octo image" align={align} src={octoImg} />}
      <ContentContainer align={align}>
        <Title ref={titleRef}>
          {title}
          {checked && <UnderLine />}
        </Title>

        <SubTitle align={align}>{subTitle}</SubTitle>
        <Desc align={align}>{desc}</Desc>
        <HashTag ref={hashTagRef} width={hashTagWidth}>
          {hashTag}
        </HashTag>
      </ContentContainer>
      {align === "right" && <OctoImg alt="octo image" align={align} src={octoImg} />}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const ContentContainer = styled.div<{ align: "left" | "right" }>`
  display: flex;
  flex-direction: column;
  z-index: 10;
  ${({ align }) =>
    align === "left"
      ? "align-items: flex-start; margin-left: 162.5px;"
      : "align-items: flex-end; margin-right: 162.5px;"};

  @media (max-width: 768px) {
    align-items: flex-start;
    margin-left: auto;
    margin-right: auto;
  }
`;

const Title = styled.h2`
  position: relative;
  display: inline-block;
  color: #ffffff;
  font-size: 52px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: -2.6px;
  white-space: pre-wrap;
  font-family: Pretendard;
  z-index: 2;

  @media (max-width: 1300px) {
    font-size: 4vw;
    letter-spacing: -0.2vw;
  }

  @media (max-width: 768px) {
    font-size: 7vw;
  }
`;

const paint = keyframes`
  0%{
    width:0px;
  }

  100%{
    width:100%;
  }
`;

const UnderLine = styled.div`
  position: absolute;
  background-color: #41d1cc;
  width: 0px;
  height: 13px;
  bottom: 0;
  z-index: -1;
  animation: ${paint} 0.5s 0.5s ease-out forwards;

  @media (max-width: 1300px) {
    height: 1vw;
  }

  @media (max-width: 768px) {
    height: 2vw;
  }
`;

const SubTitle = styled.h3<{ align: "left" | "right" }>`
  text-align: ${({ align }) => (align === "left" ? "left" : "right")};
  margin-top: 30px;
  font-size: 22.75px;
  line-height: 1.2;
  letter-spacing: -1.2px;
  font-family: Pretendard;
  color: #ffffff;
  white-space: pre-wrap;

  @media (max-width: 1300px) {
    margin-top: 2.3vw;
    font-size: 1.75vw;
    letter-spacing: -0.1vw;
  }

  @media (max-width: 768px) {
    text-align: left;
    margin-top: 3vw;
    font-size: 4vw;
  }
`;

const Desc = styled.div<{ align: "left" | "right" }>`
  text-align: ${({ align }) => (align === "left" ? "left" : "right")};
  margin-top: 25px;
  font-size: 16.25px;
  font-weight: lighter;
  color: #d8d8d8;
  line-height: 1.2;
  white-space: pre-wrap;
  font-family: Pretendard;

  @media (max-width: 1300px) {
    margin-top: 1.9vw;
    font-size: 1.25vw;
  }

  @media (max-width: 768px) {
    text-align: left;
    margin-top: 3vw;
    font-size: 2.5vw;
  }
`;

const swipe = (width: number) => keyframes`
  0% { background-position: 0; }
  100% { background-position: ${width}px; }
`;

const HashTag = styled.div<{ width: number }>`
  margin-top: 25px;
  font-size: 20px;
  font-weight: lighter;
  color: #c2a8ff;
  font-family: Pretendard;
  background: linear-gradient(to right, #c2a8ff 0, #fff 10%, #fff 20%, #c2a8ff 30%);
  background-position: 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-size-adjust: none;
  animation: ${({ width }) => swipe(width)} 2s infinite linear forwards;

  @media (max-width: 1300px) {
    margin-top: 1.9vw;
    font-size: 1.5vw;
  }

  @media (max-width: 768px) {
    margin-top: 3vw;
    font-size: 2.5vw;
  }
`;

const OctoImg = styled.img<{ align: "left" | "right" }>`
  position: absolute;
  top: -91px;
  width: 162.5px;
  height: 162.5px;
  object-fit: contain;
  ${({ align }) => (align === "left" ? "left: 0;" : "right: 0;")}

  @media (max-width: 1300px) {
    top: 7vw;
    width: 12.5vw;
    height: 12.5vw;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export default OctoText;
