import { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";

import { keyframes } from "@emotion/react";

import OctoL01 from "assets/img/octo/OctoL01.png";
import OctoR02 from "assets/img/octo/OctoR02.png";
import { useTranslation } from "react-i18next";

const Handy = () => {
  const { t } = useTranslation();
  const descRef = useRef<HTMLDivElement>(null);

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(checkIntersection, { threshold: 0.3 });

    if (descRef.current) observer.observe(descRef.current);

    return () => {
      if (descRef.current) observer.unobserve(descRef.current);
    };
  }, [descRef]);

  const checkIntersection = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries;
    setChecked(entry.isIntersecting);
  };

  return (
    <Container>
      <ContantContainer>
        <Title>{t("handyTitle")}</Title>
        <SubTitle>{t("handySubtitle")}</SubTitle>
        <TextContainer>
          <ImgContainer>
            <LeftImgContainer>
              <LeftImg alt="octo image" src={OctoL01} isOnScreen={checked} />
            </LeftImgContainer>
            <RightImgContainer>
              <RightImg alt="octo image" src={OctoR02} isOnScreen={checked} />
            </RightImgContainer>
          </ImgContainer>
          <AlignDesc>
            <DescContainer align={"left"}>
              <Desc ref={descRef}>
                <Accent isOnScreen={checked} data-text={t("handyTextTitle1")}>
                  {t("handyTextTitle1")}
                  {checked && <UnderLine />}
                </Accent>
                {t("handyTextTitle2")}
              </Desc>
              <SubDesc>{t("handyTextDesc1")}</SubDesc>
            </DescContainer>
            <DescContainer align={"right"}>
              <ul style={{ listStyle: "disc" }}>
                <SubDesc2>{t("handyTextDesc2")}</SubDesc2>
                <SubDesc2>{t("handyTextDesc3")}</SubDesc2>
              </ul>
            </DescContainer>
          </AlignDesc>
        </TextContainer>
      </ContantContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(to right, #ffcf0e 50%, #8539f2 50%);
  overflow: hidden;

  @media (max-width: 768px) {
    height: auto;
    background-color: #ffcf0e;
    background-image: none;
  }
`;

const ContantContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1400px;
  width: 1400px;
  padding: 0 50px;

  @media (max-width: 1300px) {
    padding: 0 3.8vw;
  }

  @media (max-width: 768px) {
    padding: 0 5vw;
  }
`;

const Title = styled.div`
  margin-top: -115px;
  font-size: 70px;
  font-weight: bold;
  letter-spacing: -2.8px;
  font-family: Pretendard;
  color: #ffffff;

  @media (max-width: 1300px) {
    margin-top: -8.8vw;
    font-size: 5.4vw;
    letter-spacing: -0.2vw;
  }

  @media (max-width: 768px) {
    margin-top: 10vw;
    font-size: 8vw;
  }
`;

const SubTitle = styled.div`
  margin-top: 13px;
  font-size: 28px;
  font-weight: lighter;
  letter-spacing: -1.12px;
  color: #ffffff;
  font-family: Pretendard;

  @media (max-width: 1300px) {
    margin-top: 1vw;
    font-size: 2.2vw;
    letter-spacing: -0.1vw;
  }

  @media (max-width: 768px) {
    margin-top: 1.5vw;
    font-size: 4vw;
  }
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  box-sizing: border-box;
  margin-top: 150px;

  @media (max-width: 1300px) {
    margin-top: 11.5vw;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ImgContainer = styled.div`
  position: absolute;
  display: flex;
  width: 100%;

  @media (max-width: 768px) {
    /* display: none; */
  }
`;

const AlignDesc = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20vw;
    margin-bottom: 100px;
  }
`;

const LeftImgContainer = styled.div`
  position: relative;
  width: 50%;
  height: 536px;
  overflow: hidden;

  @media (max-width: 1300px) {
    height: 41.2vw;
  }

  @media (max-width: 768px) {
    height: 90vw;
    overflow: visible;
  }
`;
const RightImgContainer = styled.div`
  position: relative;
  width: 50%;
  height: 536px;
  overflow: hidden;

  @media (max-width: 1300px) {
    height: 41.2vw;
  }
  @media (max-width: 768px) {
    height: 90vw;
    overflow: visible;
  }
`;

const visible = (value: "right" | "left") => keyframes`
  0%{
    ${value}: -350px;
  }

  100% {
    ${value}: -170px;
  }
`;

const visibleMiddle = (value: "right" | "left") => keyframes`
  0%{
    ${value}: -26.9vw;
  }

  100% {
    ${value}: -13.1vw;
  }
`;

const LeftImg = styled.img<{ isOnScreen: boolean }>`
  position: absolute;
  right: -350px;
  width: 536px;
  height: 536px;
  object-fit: contain;
  animation: ${({ isOnScreen }) => isOnScreen && visible("right")} 0.5s 0.5s ease-out forwards;

  @media (max-width: 1300px) {
    right: -26.9vw;
    width: 41.2vw;
    height: 41.2vw;
    animation: ${({ isOnScreen }) => isOnScreen && visibleMiddle("right")} 0.5s 0.5s ease-out forwards;
  }

  @media (max-width: 768px) {
    top: 0vw;
    right: -40vw;
    animation: none;
  }
`;

const RightImg = styled.img<{ isOnScreen: boolean }>`
  position: absolute;
  left: -350px;
  width: 536px;
  height: 536px;
  object-fit: contain;
  animation: ${({ isOnScreen }) => isOnScreen && visible("left")} 0.5s 0.5s ease-out forwards;

  @media (max-width: 1300px) {
    left: -26.9vw;
    width: 41.2vw;
    height: 41.2vw;
    animation: ${({ isOnScreen }) => isOnScreen && visibleMiddle("left")} 0.5s 0.5s ease-out forwards;
  }

  @media (max-width: 768px) {
    bottom: 15vw;
    left: -45vw;
    animation: none;
  }
`;

const DescContainer = styled.div<{ align: "left" | "right" }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 370px;

  @media (max-width: 1300px) {
    width: 26vw;
  }

  @media (max-width: 768px) {
    width: 100%;
    align-items: ${({ align }) => (align === "left" ? "flex-start" : "flex-end")};
  }
`;

const Desc = styled.div`
  font-size: 50px;
  font-weight: bold;
  letter-spacing: -2px;
  font-family: Pretendard;
  color: #fff;
  white-space: nowrap;

  @media (max-width: 1300px) {
    font-size: 3.8vw;
  }

  @media (max-width: 768px) {
    font-size: 7vw;
  }
`;

const Accent = styled.span<{ isOnScreen: boolean }>`
  position: relative;
  z-index: 1;

  ::before {
    content: attr(data-text);
    position: absolute;
    left: 0;
    color: #0022ff;
    width: ${({ isOnScreen }) => (isOnScreen ? "100%" : 0)};
    overflow: hidden;
    white-space: nowrap;
    transition: 0.5s 0.5s ease-out;
  }
`;

const paint = keyframes`
  0%{
    width:0px;
  }

  100%{
    width:100%;
  }
`;

const UnderLine = styled.div`
  position: absolute;
  background-color: #41d1cc;
  width: 0px;
  height: 20px;
  bottom: 0;
  z-index: -1;
  animation: ${paint} 0.5s 0.5s ease-out forwards;

  @media (max-width: 1300px) {
    height: 1.5vw;
  }
`;

const SubDesc = styled.div`
  margin-top: 13px;
  font-size: 22px;
  font-weight: lighter;
  letter-spacing: -0.88px;
  color: #ffffff;
  line-height: 1.3;
  font-family: Pretendard;
  white-space: pre-wrap;

  @media (max-width: 1300px) {
    margin-top: 1vw;
    font-size: 1.7vw;
  }

  @media (max-width: 768px) {
    font-size: 3.5vw;
  }
`;

const SubDesc2 = styled.li`
  margin-top: 13px;
  font-size: 20px;
  font-weight: lighter;
  letter-spacing: -0.88px;
  color: #ffffff;
  font-family: Pretendard;
  line-height: 1.3;
  white-space: pre-wrap;

  @media (max-width: 1300px) {
    margin-top: 1vw;
    font-size: 1.7vw;
  }

  @media (max-width: 768px) {
    font-size: 3vw;
  }
`;

export default Handy;
