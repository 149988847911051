import styled from "@emotion/styled";

import logoKr from "assets/img/logo_KR.png";
import logoEn from "assets/img/logo_EN.png";
import lang from "assets/img/lang.png";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { WHITEPAPER_EN, WHITEPAPER_KR } from "config";
import Sidebar from "components/Sidebar";
import MenuIcon from "@mui/icons-material/Menu";

const Navbar = () => {
  const { t, i18n } = useTranslation();

  const [visible, setVisible] = useState(false);
  const [isActiveSidebar, setIsActiveSidebar] = useState(false);

  const toggleLocales = useCallback(
    (locale: string) => {
      i18n.changeLanguage(locale);
    },
    [i18n]
  );

  return (
    <Container>
      {isActiveSidebar && <Sidebar setIsActiveSidebar={setIsActiveSidebar} />}
      <ContentContainer>
        <Link to="/" style={{ textDecoration: "none" }}>
          <Logo src={t("lang_code") === "KR" ? logoKr : logoEn} />
          <Title>handypick, handypick live, 맞춰볼래, 맞춰볼래 live</Title>
        </Link>
        <Hamberger onClick={() => setIsActiveSidebar(true)}>
          <MenuIcon sx={{ marginBottom: "-10px", color: "#fff" }} fontSize="large" />
        </Hamberger>
        <Nav>
          <Anchor
            href={t("lang_code") === "KR" ? WHITEPAPER_KR : WHITEPAPER_EN}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Item>Whitepaper</Item>
          </Anchor>
          <Anchor href={"mailto:hello@handypick.io"}>
            <Item>CONTACT</Item>
          </Anchor>
          <IconContainer>
            <Icon alt="language icon" src={lang} onClick={() => setVisible((prev) => !prev)} />
            {visible && (
              <LangList>
                <List
                  isSelected={t("lang_code") === "KR"}
                  onClick={() => {
                    toggleLocales("ko");
                    setVisible(false);
                  }}
                >
                  KOR
                </List>
                <List
                  isSelected={t("lang_code") === "EN"}
                  onClick={() => {
                    toggleLocales("en");
                    setVisible(false);
                  }}
                >
                  ENG
                </List>
              </LangList>
            )}
          </IconContainer>
        </Nav>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100px;
  z-index: 100;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1300px;
  width: 1300px;
  padding: 0 50px;

  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

const Logo = styled.img`
  width: 158px;
  height: 44px;
  object-fit: contain;
  cursor: pointer;
`;

const Title = styled.h1`
  display: none;
`;

const Nav = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 55px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Item = styled.div`
  color: #fff;
  font: normal normal bold 18px/22px Pretendard;
  cursor: pointer;
`;

const IconContainer = styled.div`
  position: relative;
`;

const Icon = styled.img`
  width: 25px;
  height: 25px;
  object-fit: contain;
  cursor: pointer;
`;

const LangList = styled.ul`
  position: absolute;
  left: 0;
  top: 27px;
  padding: 10px;
  background-color: #fff;
  color: #777;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
`;

const List = styled.li<{ isSelected: boolean }>`
  padding: 7px 5px;
  font-family: "Spoqa Han Sans";
  font-weight: bold;
  color: ${({ isSelected }) => (isSelected ? "#363636" : "#777777")};

  :hover {
    background-color: #ccc5;
    color: #343646;
  }
`;

const Anchor = styled.a`
  text-decoration: none;
`;

const Hamberger = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

export default Navbar;
